// import 'core-js/es6/promise'
// import 'core-js/es6/string'
// import 'core-js/es7/array'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store'
import { ClientTable } from 'vue-tables-2'
import 'vue-loaders/dist/vue-loaders.css'
import VueLoaders from 'vue-loaders'
import * as AmplifyModules from 'aws-amplify'
import VueAnalytics from 'vue-analytics'
import config from '@/config'

import "core-js/stable";
import "regenerator-runtime/runtime";

Vue.use(BootstrapVue)
Vue.use(ClientTable)
Vue.use(router)
Vue.use(VueLoaders)
Vue.use(AmplifyModules)
// GA上での設定も必要。
// 1.URL設定　管理>プロパティ設定>デフォルトのURLの項目
//    分析対象のURL(ドメイン)を指定
// 2.フィルタ設定　管理>フィルタ>フィルタ追加
//    1で設定したドメインのみを許可
Vue.use(VueAnalytics, {
  id: `${config.trackingId}`,
  router
})

const v = new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})

v.$mount()
