const state = {
  prvKey: null
}

const mutations = {
  SET_PRV_KEY(state, prvKey) {
    state.prvKey = prvKey
  }
}

const actions = {
  async setPrvKey({ commit }, prvKey) {
    commit('SET_PRV_KEY', prvKey)
  },
  async resetPrvKey({ commit }) {
    commit('SET_PRV_KEY', null)
  }
}

const getters = {
  getPrvKey: (state) => {
    return state.prvKey
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
