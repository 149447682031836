<template>
  <router-view></router-view>
</template>

<script>
import * as authUtil from '@/lib/authUtil'
export default {
  name: 'app',
  async created() {
    try {
      const user = await authUtil.currentAuthenticatedUser()
      this.$ga.set('userId', user.username)
    } catch (e) {
      this.$ga.set('userId', null)
      // GAに使うだけだからthrowしない
    }
  }
}
</script>

<style lang="scss">
/* Import Font Awesome Icons Set */
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/css/all.css';
/* Import Bootstrap Vue Styles */
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@coreui/icons/css/coreui-icons.css';
@import '~@coreui/coreui/scss/coreui';
@import '@/../src/assets/scss/style.scss';
@import '@/../src/assets/scss/_base-component.scss';
@import '@/../src/assets/scss/tooltip.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '~animate.css/animate.min.css';
</style>
