const state = {
  // ダッシュボード画面用
  datasetName: '',
  datasetId: '',
  datasetRecords: '',
  dataSource: '',
  // 比較分析画面用
  comparedDatasets: []
}

const mutations = {
  INIT_DATASET_INFO(state, info) {
    state.datasetName = info.data_set_name
    state.datasetId = info.id
    state.datasetRecords = info.records
    state.dataSource = info.data_source
  },
  INIT_COMPARED_DATASETS(state, datasetList) {
    state.comparedDatasets = datasetList
  }
}

const actions = {
  async initDatasetInfo({ commit }, info) {
    commit('INIT_DATASET_INFO', info)
  },
  async initComparedDatasets({ commit }, datasetList) {
    commit('INIT_COMPARED_DATASETS', datasetList)
  }
}

const getters = {
  getDatasetId: state => {
    return state.datasetId
  },
  getDatasetName: state => {
    return state.datasetName
  },
  getDatasetRecords: state => {
    return state.datasetRecords
  },
  getComparedDatasets: state => {
    return state.comparedDatasets
  },
  getDataSource: state => {
    return state.dataSource
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
