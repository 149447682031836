import config from '@/config'
import * as crypto from 'crypto'
//import { createDecipheriv } from 'crypto'
import store from '@/store/index.js'
import moment from 'moment'
import { getCookie, removeCookie, setCookie } from '@/lib/cookieUtil'
import axios from 'axios'
import { Buffer } from 'buffer';

export function signIn() {
  const redirectUrl = Buffer.from(config.auth.redirectUrl).toString('base64')
  const dAuthUrl = `https://${config.auth.dAuthHost}/auth/web/Login.jsp?aplid=${
    config.auth.aplId
  }&rd=${redirectUrl}`
  window.location = dAuthUrl
}

export async function signOut() {
  // deprecated
  await store.dispatch('resetUserId')
  await store.dispatch('resetUserName')
  await store.dispatch('resetPrvKey')
  await store.dispatch('resetEmail')
  removeCookie(config.authCommon.sessionProp)
}

export function isAuthenticated() {
  try {
    const dAuthToken = getCookie(config.authCommon.sessionProp)
    // tokenがCookieにない場合は未認証とみなす
    if (!dAuthToken) return false

    return parseJWE(dAuthToken)
  } catch (e) {
    throw e
  }
}

export function needAuthtimeCheck() {
  if (!getCookie(config.authCommon.timeCheckFlg)) return true
  else return false
}

export async function isFreshAuth() {
  const dAuthToken = getCookie(config.authCommon.sessionProp)
  const authResult = await getAuthResultPayload(dAuthToken)

  // 認証時間が規定の範囲外の場合は未認証とみなす
  return isWithin(authResult.time, config.authCommon.withinSeconds)
}

export function isWithin(time, intervalSeconds) {
  return moment().diff(moment(time), 'seconds') < intervalSeconds
}

export async function initialize() {
  if (!store.getters.getUserId) await setUserInfo('setUserId', 'id')
  if (!getCookie(config.authCommon.timeCheckFlg))
    setCookie(config.authCommon.timeCheckFlg, true)
  if (!store.getters.getUserName) await setUserInfo('setUserName', 'name_en')
  if (!store.getters.getEmail) await setUserInfo('setEmail', 'mail')
  if (!store.getters.getPrvKey) {
    const key = await fetchPrvKey()
    await store.dispatch('setPrvKey', key)
  }
}

export async function setUserInfo(storeMethod, prop) {
  const dAuthToken = getCookie(config.authCommon.sessionProp)
  const authResult = await getAuthResultPayload(dAuthToken)
  await store.dispatch(storeMethod, authResult[prop])
}

export async function currentSession() {
  return new Promise((resolve, reject) => {
    if (!isAuthenticated()) reject(new Error('error'))
    const userSession = {
      accessToken: {
        payload: {
          sub: store.getters.getUserId
        }
      }
    }
    resolve(userSession)
  })
}

export async function currentAuthenticatedUser() {
  return store.getters.getUserName
}

const getAuthResultPayload = async dAuthToken => {
  try {
    const { esqAuthResult, IV } = parseJWE(dAuthToken)
    const decrypted = await decrypt(esqAuthResult, IV)
    return decrypted
  } catch (e) {
    throw e
  }
}

const fetchPrvKey = async () => {
  const { data: prvKey } = await axios.get(
    `${config.api.endpoint}/api/v0/auth/prvkey`
  )
  return prvKey
}

const decrypt = async (encodedAuthResult, encodedIv) => {
  let key = store.getters.getPrvKey
  if (!key) {
    key = await fetchPrvKey()
    await store.dispatch('setPrvKey', key)
  }
  const authResult = decodeBase64(encodedAuthResult)
  const iv = decodeBase64(encodedIv)
  const decipher = crypto.createDecipheriv(config.authCommon.encryptAlgorithm, key, iv)
  //const decipher = createDecipheriv(config.authCommon.encryptAlgorithm, key, iv)
  const decodedData = decipher.update(authResult)
  const decodedDataStr = Buffer.concat([
    decodedData,
    decipher.final()
  ]).toString('utf8')

  return authResToJson(decodedDataStr)
}

const decodeBase64 = encodedString => {
  return Buffer.from(encodedString, 'base64')
}

const parseJWE = dAuthToken => {
  try {
    const [_, IV, esqAuthResult] = dAuthToken.split('.')
    if (!(_ && esqAuthResult && IV)) throw new Error('invalid d-auth token')
    return { esqAuthResult, IV }
  } catch (e) {
    throw e
  }
}

const authResToJson = authResStr => {
  const authResJSON = {}
  authResStr
    .trim()
    .split('\n')
    .forEach(item => {
      const [prop, value] = item.split('||')
      authResJSON[prop] = value
    })
  return authResJSON
}
