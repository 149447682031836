// /////////////////////////////////////////////////////////////////
// Copyright (C) 2018 by Hitachi, Ltd. All rights reserved.
// /////////////////////////////////////////////////////////////////
import config from '@/config/config'
const env = process.env.VUE_APP_CONF_ENV || 'development'
// デフォルト設定をロード
const defaultConfig = Object.assign({}, config['default'])
// 環境ごとの設定値で上書き
const mergedConfig = Object.assign(defaultConfig, config[env])

export default mergedConfig
