import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  strict: process.env.VUE_APP_CONF_ENV !== 'production',
  plugins: [
    createPersistedState({ key: 'mindlook', storage: window.sessionStorage })
  ]
})
