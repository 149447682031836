import Vue from 'vue'
import Router from 'vue-router'
import {
  isAuthenticated,
  signIn,
  initialize,
  needAuthtimeCheck,
  isFreshAuth
} from '../lib/authUtil'

// Containers
const SideLessContainer = () => import('@/containers/SideLessContainer')
const DataManageContainer = () => import('@/containers/DataManageContainer')

// Views
// const UserManagement = () => import('@/views/UserManagement')
const DatasetList = () => import('@/views/DatasetList')
const DatasetEntry = () => import('@/views/DatasetEntry')
const ExternalDatasetList = () => import('@/views/ExternalDatasetList')
const ExternalDatasetEntry = () => import('@/views/ExternalDatasetEntry')
const DashboardDatasetSelector = () =>
  import('@/views/DashboardDatasetSelector')
// const Login = () => import('@/views/Login')
// const Register = () => import('@/views/Register')
// const ForgotPassword = () => import('@/views/ForgotPassword')
// const Setting = () => import('@/views/Setting')
// const Help = () => import('@/views/Help')
// const Notice = () => import('@/views/Notice')
const StartMenu = () => import('@/views/StartMenu')
const ComparativeAnalysis = () => import('@/views/ComparativeAnalysis')
const CompareDatasetWizard = () => import('@/views/CompareDatasetSelector')
const DeepAnalysis = () => import('@/views/DeepAnalysis')
const UserAnalysis = () => import('@/views/UserAnalysis')
const ExternalDataAnalysis = () => import('@/views/ExternalDataAnalysis')
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'ルート',
      redirect: '/startMenu',
      component: SideLessContainer,
      props: {
        showBreadcrumb: false
      },
      children: []
    },
    {
      path: '/startMenu',
      name: '初期メニュー',
      redirect: '/startMenu/menu',
      component: SideLessContainer,
      props: {
        showBreadcrumb: false
      },
      children: [
        {
          path: 'menu',
          meta: {
            title: 'mindlook スタートメニュー',
            desc: 'スタートメニュー画面です。',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } }
            ]
          },
          component: StartMenu
        }
      ]
    },
    {
      path: '/dataManagement',
      redirect: '/dataManagement/register_text',
      name: 'データ管理',
      component: DataManageContainer,
      children: [
        {
          path: 'register_text',
          name: 'データセット登録_テキストデータ',
          meta: {
            title: 'mindlook データセット登録',
            desc: 'データセット登録画面です。',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: 'データを登録・確認する',
                to: { path: '/dataManagement/register_text' }
              },
              { text: 'データセット登録（感性分析）' }
            ]
          },
          component: DatasetEntry
        },
        {
          path: 'register_ext',
          name: 'データセット登録_時系列データ',
          meta: {
            title: 'mindlook データセット登録',
            desc: 'データセット登録画面です。',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: 'データを登録・確認する',
                to: { path: '/dataManagement/register_ext' }
              },
              { text: 'データセット登録（時系列データ）' }
            ]
          },
          component: ExternalDatasetEntry
        },
        {
          path: 'list_text',
          name: 'データセット一覧_テキストデータ',
          meta: {
            title: 'mindlook データセット一覧',
            desc: 'データセット一覧の画面です。',
            dataType: 'Text',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: 'データを登録・確認する',
                to: { path: '/dataManagement/list_text' }
              },
              { text: 'データセット一覧（感性分析）' }
            ]
          },
          component: DatasetList
        },
        {
          path: 'list_ext',
          name: 'データセット一覧_時系列データ',
          meta: {
            title: 'mindlook データセット一覧（時系列データ）',
            desc: 'データセット（時系列データ）一覧の画面です。',
            dataType: 'External',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: 'データを登録・確認する',
                to: { path: '/dataManagement/list_ext' }
              },
              { text: 'データセット一覧（時系列データ）' }
            ]
          },
          component: ExternalDatasetList
        }
      ]
    },
    {
      path: '/dashboard',
      redirect: '/dashboard/selectDataset_twitter',
      name: '１つのデータを深掘りする',
      component: SideLessContainer,
      children: [
        {
          path: 'selectDataset_twitter',
          name: 'データセット選択_Twitter分析',
          meta: {
            title: 'mindlook データセット選択',
            desc: 'ダッシュボードのデータセット選択画面です。',
            dataType: 'Twitter',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '１つのデータを深掘りする',
                to: { path: '/dashboard/selectDataset_twitter' }
              },
              { text: 'データ選択画面' }
            ]
          },
          component: DashboardDatasetSelector
        },
        {
          path: 'selectDataset_general',
          name: 'データセット選択_テキスト分析',
          meta: {
            title: 'mindlook データセット選択',
            desc: 'ダッシュボードのデータセット選択画面です。',
            dataType: 'その他テキストデータ',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '１つのデータを深掘りする',
                to: { path: '/dashboard/selectDataset_general' }
              },
              { text: 'データ選択画面' }
            ]
          },
          component: DashboardDatasetSelector
        },
        {
          path: 'dashboardView_twitter',
          name: 'ダッシュボードビュー_Twitter分析',
          meta: {
            title: 'mindlook １つのデータを深掘りする',
            desc: 'ダッシュボードの画面です。',
            dataType: 'Twitter',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '１つのデータを深掘りする',
                to: { path: '/dashboard/selectDataset_twitter' }
              }
            ]
          },
          component: DeepAnalysis
        },
        {
          path: 'dashboardView_general',
          name: 'ダッシュボードビュー_テキスト分析',
          meta: {
            title: 'mindlook １つのデータを深掘りする',
            desc: 'ダッシュボードの画面です。',
            dataType: 'その他テキストデータ',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '１つのデータを深掘りする',
                to: { path: '/dashboard/selectDataset_general' }
              }
            ]
          },
          component: DeepAnalysis
        },
        {
          path: 'bioView',
          name: 'ユーザー分析ビュー',
          meta: {
            title: 'mindlook ユーザー分析',
            desc: 'ユーザー分析の画面です。',
            dataType: 'Twitter',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '１つのデータを深掘りする',
                to: { path: '/dashboard/selectDataset_twitter' }
              }
            ]
          },
          component: UserAnalysis
        }
      ]
    },
    {
      path: '/compare',
      name: '比較分析',
      component: SideLessContainer,
      redirect: '/compare/selectDataset',
      children: [
        {
          path: 'selectDataset',
          name: '比較データセット選択ウィザード',
          meta: {
            title: 'mindlook 比較データセット選択',
            desc: '比較データセット選択画面です。',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '複数のデータを比較する',
                to: { path: '/compare' }
              },
              { text: 'データ選択画面' }
            ]
          },
          component: CompareDatasetWizard
        },
        {
          path: 'view',
          name: '比較分析ビュー',
          meta: {
            title: 'mindlook 複数のデータセットを比較する',
            desc: '比較分析の画面です。',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '複数のデータを比較する',
                to: { path: '/compare' }
              },
              { text: '分析画面' }
            ]
          },
          component: ComparativeAnalysis
        }
      ]
    },
    {
      path: '/combined',
      name: '時系列データ分析',
      component: SideLessContainer,
      redirect: '/combined/timeline',
      children: [
        {
          path: 'timeline',
          name: '時系列データ分析ビュー',
          meta: {
            title: 'mindlook 時系列データ分析',
            desc: '時系列データ分析の画面です。',
            breadcrumb: [
              { text: 'スタートメニュー', to: { path: '/startMenu' } },
              {
                text: '時系列データと組み合わせて分析する',
                to: { path: '/combined' }
              }
            ]
          },
          component: ExternalDataAnalysis
        }
      ]
    }
  ]
})
router.beforeEach(async (to, from, next) => {
  if (!isAuthenticated()) signIn()

  // 認証直後のみ認証時間チェック
  if (needAuthtimeCheck()) {
    // 認証時間が規定の範囲外の場合は再認証
    if (!(await isFreshAuth())) signIn()
  }
  await initialize()

  document.title = to.meta.title

  next()
})

export default router
