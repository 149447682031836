import Cookies from 'js-cookie'

export const getCookie = cookieKey => {
  return Cookies.get(cookieKey)
}

export const setCookie = (cookieKey, data) => {
  var jsonData = JSON.stringify(data)
  Cookies.set(cookieKey, jsonData, { path: '/', secure: true, samesite: 'lax' })
}

export const removeCookie = cookieKey => {
  Cookies.remove(cookieKey)
}
