const state = {
  userId: null,
  userName: null,
  email: null,
  isMaintainer: false
}

const mutations = {
  SET_USER_ID(state, userId) {
    state.userId = userId
  },
  SET_USER_NAME(state, userName) {
    state.userName = userName
  },
  SET_EMAIL(state, email) {
    state.email = email
  },
  SET_MAINTAINER_FLAG(state, maintainerFlag) {
    state.isMaintainer = maintainerFlag
  }
}

const actions = {
  async setUserId({ commit }, userId) {
    commit('SET_USER_ID', userId)
  },
  async resetUserId({ commit }) {
    commit('SET_USER_ID', null)
  },
  async setUserName({ commit }, userName) {
    commit('SET_USER_NAME', userName)
  },
  async resetUserName({ commit }) {
    commit('SET_USER_NAME', null)
  },
  async setEmail({ commit }, email) {
    commit('SET_EMAIL', email)
  },
  async resetEmail({ commit }) {
    commit('SET_EMAIL', null)
  },
  async setMaintainerFlag({ commit }, maintainerFlag) {
    commit('SET_MAINTAINER_FLAG', maintainerFlag)
  },
  async resetMaintainerFlag({ commit }) {
    commit('SET_MAINTAINER_FLAG', false)
  }
}

const getters = {
  getUserId: (state) => {
    return state.userId
  },
  getUserName: (state) => {
    return state.userName
  },
  getEmail: (state) => {
    return state.email
  },
  isMaintainer: (state) => {
    return state.isMaintainer
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
